/* Global box-sizing and overflow control */
* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Full viewport height */
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

.main-content {
  flex: 1; /* Allows the content to grow and push the footer down */
}

/* Footer container */
.footer {
  background-color: #000000;
  text-align: center;
  color: white;
  width: 100%;
  overflow-x: hidden;
  flex-shrink: 0;
  margin-top: auto;
}

/* Footer content styling */
.footer-content {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 1000px;
  width: 100%;
}

/* Contact Info styling */
.contact-info a {
  color: #61dafb;
  text-decoration: none;
}

.contact-info a:hover {
  text-decoration: underline;
}

.social-links a {
  color: #61dafb;
  margin: 0 5px;
  text-decoration: none;
  font-weight: bold;
}

.social-links a:hover {
  text-decoration: underline;
}

/* Copyright styling */
.copyright {
  font-size: 0.9em;
  color: #aaaaaa;
  margin-top: 10px;
}

.social-logo {
  width: 20px; /* Adjust logo size */
  height: 20px; /* Adjust logo size */
  margin-right: 8px; /* Space between logo and text */
}