/* Header Container */
.Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0); /* Fully transparent background */
  position: fixed; /* Fixed position to follow the viewport */
  top: 0; /* Stick to the top of the viewport */
  left: 0;
  width: 100%;
  border: 1px solid rgba(255, 255, 255, 0.2);
  z-index: 100; /* Ensure the header stays on top */
  animation: fade-in 1s ease-in-out;
  box-sizing: border-box;
}

/* Logo Styling */
.Header .logo h1 {
  color: #61dafb;
  margin: 0;
  font-size: 2.5rem;
  animation: slide-in-left 1s ease-in-out;
}

/* Navigation List Styling */
nav {
  flex-grow: 1;
  margin-left: -85px;
}

nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding-left: 5px;
}

/* Navigation List Items */
nav ul li {
  margin-right: 0;
  opacity: 0;
  animation: fade-in-up 0.5s ease forwards;
}

nav ul li:nth-child(1) {
  animation-delay: 0.2s;
}

nav ul li:nth-child(2) {
  animation-delay: 0.4s;
}

nav ul li:nth-child(3) {
  animation-delay: 0.6s;
}

/* Navigation Links */
nav ul li a {
  color: white;
  text-decoration: none;
  font-weight: bold;
  font-size: 1.2rem;
  padding: 10px 20px;
  background-color: #3c3f45;
  border-radius: 50px;
  transition:
    background-color 0.3s ease,
    transform 0.3s ease;
}

nav ul li a:hover {
  background-color: #61dafb;
  color: #282c34;
  transform: translateY(-5px);
}

/* CTA Button Styling */
.cta .btn {
  background-color: #61dafb;
  color: white;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 50px;
  font-weight: bold;
  transition:
    background-color 0.3s ease,
    transform 0.3s ease;
  opacity: 0;
  animation: fade-in-up 0.8s ease forwards;
  white-space: nowrap;
}

.cta .btn:hover {
  background-color: #21a1f1;
  transform: translateY(-5px);
}

/* Animations */
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slide-in-left {
  from {
    opacity: 0;
    transform: translateX(-30px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fade-in-up {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
