.contact-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: rgb(12, 12, 12); /* Light background to make it pop */
  padding: 20px;
}

.contact-content {
  text-align: center;
  background-color: rgb(12, 12, 12);
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Slight shadow for a card-like effect */
  max-width: 500px;
  width: 100%;
  z-index: 1;
}

h2 {
  color: #333;
  margin-bottom: 20px;
}

p {
  font-size: 16px;
  color: #555;
}

a {
  color: #1a73e8;
  text-decoration: none;
  font-weight: bold;
}

a:hover {
  text-decoration: underline;
}
