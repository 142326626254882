/* Main app layout using flexbox */
.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  text-align: center;
  font-family: Arial, sans-serif;
}

/* Main content area, grows to fill available space */
.MainContent {
  flex-grow: 1;
}

/* Navigation styling */
nav ul {
  list-style-type: none;
  padding: 0;
}

nav ul li {
  display: inline;
  margin-right: 20px;
}

nav ul li a {
  text-decoration: none;
  color: #61dafb;
}

nav ul li a:hover {
  color: #21a1f1;
}

/* Headers */
h1,
h2 {
  color: #ffffff;
}

p {
  color: white;
}
