.all-skills-container {
  padding: 20px;
  margin: 0 auto;
  text-align: center;
  min-height: 35rem; 
}

h2 {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 10px;
}

p {
  font-size: 1.2rem;
  color: #555;
  margin-bottom: 20px;
}

/* Search Bar */
.search-bar {
  padding: 10px;
  font-size: 1rem;
  width: 100%;
  max-width: 400px;
  margin-bottom: 20px;
  border: 2px solid #0077ff;
  border-radius: 5px;
}

/* Skills List */
.skills-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;
}

/* Skill Bubble */
.skill-bubble {
  display: inline-block;
  padding: 10px 15px;
  background-color: #0077ff;
  color: white;
  text-decoration: none;
  border-radius: 50px;
  font-size: 1.1rem;
  transition: background-color 0.3s ease;
}

.skill-bubble:hover {
  background-color: #005bb5;
}

/* No results message */
p {
  color: #666;
  font-size: 1.2rem;
}
