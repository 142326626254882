.Projects {
  padding: 10px;
  text-align: center;
  color: white;
  position: relative;
  min-height: 100vh;
  background-color: rgb(10, 25, 47);
}

.Project-card {
  background-color: rgba(255, 255, 255, 0.02);
  padding: 20px;
  margin: 30px;
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  width: 80%;
  max-width: 800px;
  transition:
    transform 0.3s ease,
    background-color 0.3s ease;
}

.Projects-wrapper {
  position: relative;
  z-index: 1;
  padding: 10vh;
  height: auto;
  min-height: calc(100vh - 100px);
  background-color: #000000;
}

.Project-card:hover {
  transform: translateY(-10px);
  background-color: rgba(255, 255, 255, 0.2);
}

.Projects-content {
  position: relative;
  z-index: 2;
}

.center-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.project-gif {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  margin-top: 10px;
}

.links {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
}

.links a {
  color: #61dafb;
  text-decoration: none;
  font-weight: bold;
  margin-bottom: 10px;
}

.links a:hover {
  text-decoration: underline;
}

.skill-bubbles {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 20px;
  overflow: hidden;
}

.bubble {
  background-color: #61dafb;
  color: white;
  padding: 10px 15px;
  border-radius: 50px;
  margin: 10px;
  animation: moveLeft 10s linear infinite;
}

.large-search {
  width: 80%;
  padding: 12px 20px;
  font-size: 18px;
  border: 2px solid rgb(204, 204, 204);
  border-radius: 8px;
  margin: 20px 0;
  box-sizing: border-box;
}

.search-input.large-search {
  background-color: rgb(12, 12, 12);
  color: rgb(255, 255, 255);
}

.large-search:focus {
  border-color: #007bff;
  outline: none;
}

.toggle-buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.toggle-buttons button {
  background-color: #0a192f;
  color: white;
  border: 2px solid #61dafb;
  padding: 10px 20px;
  margin: 0 10px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.toggle-buttons button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.toggle-buttons .active {
  background-color: #61dafb;
  color: black;
}

.Vanta-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  visibility: hidden; /* Initially hidden */
  opacity: 0; /* Initially invisible */
  transition: opacity 0.6s ease-in-out; /* Smooth fade-in/out effect */
}

.highlight {
  border: 2px solid #006aff;
  animation: highlight-animation 2s ease-out;
}

@keyframes highlight-animation {
  from {
    background-color: #006aff;
  }
  to {
    background-color: transparent;
  }
}
