/* General Layout */
.Home {
  display: flex;
  flex-direction: column; /* Stack the content and footer vertically */
  justify-content: space-between; /* Ensure footer stays at the bottom */
  min-height: 100vh;
  position: relative;
  text-align: center;
  padding: 100px;
  overflow: hidden;
}

.home-content {
  max-width: 1000px;
  width: 100%; /* Ensures consistent width */
  min-height: auto;
  margin: 0 auto;
  padding: 20px;
  background-color: rgb(12, 12, 12);
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 1;
}

/* Headings and Paragraphs */
h1 {
  font-size: 3rem;
  color: #333;
  margin-bottom: 20px;
}

p {
  font-size: 1.2rem;
  color: #555;
  margin-bottom: 40px;
  line-height: 1.5;
}

/* Carousel Wrapper for Animation */
.carousel-wrapper {
  margin-top: 20px;
}

/* Animations */

/* Fade-in animation for the heading */
.fade-in {
  opacity: 0;
  animation: fade-in 1.2s ease-in-out forwards;
  color: #dfdfdf;
}

@keyframes fade-in {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Slide-in animation for the paragraph */
.slide-in {
  opacity: 0;
  animation: slide-in 1.4s ease forwards;
  color: #dfdfdf;
  font-size: 20;
  font-size: 1.2rem;
}

@keyframes slide-in {
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Fade-in for the carousel with a slight delay */
.fade-in-later {
  opacity: 0;
  animation: fade-in 1.6s ease-in-out forwards;
}

/* Make sure Vanta.js background works properly */
.Home::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0; /* Below content */
}

.Home {
  background-color: #000000;
}

.search-bar {
  background-color: rgb(12, 12, 12);
  color: white;
}

.section-box {
  background-color: rgb(0, 0, 0); /* Black background */
  margin: 20px 0;
  padding: 20px;
  border-radius: 20px; /* Rounded corners */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2); /* Shadow for depth */
  text-align: center;
  z-index: 2;
}

.Vanta-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  visibility: hidden; /* Initially hidden */
  opacity: 0; /* Initially invisible */
  transition: opacity 0.6s ease-in-out; /* Smooth fade-in/out effect */
}
