/* Updated carousel styling to prevent shrinking and fix navigation button placement */
.carousel {
  position: relative;
  width: 100%;
  max-width: 800px; /* Increase max width to accommodate larger images */
  margin: 0 auto;
  text-align: center;
  overflow: hidden; /* Ensure content stays within the container */
  padding-bottom: 40px; /* Add space at the bottom for the indicators */
}

/* Ensure carousel content stays within the fixed size */
.carousel-content {
  position: relative;
  padding: 20px;
  transition: opacity 0.5s ease;
  opacity: 1;
  overflow: hidden; /* Hide overflowed content */
  box-sizing: border-box; /* Ensures padding doesn't increase the width/height */
}

/* Ensure carousel content stays within the fixed size and leaves room for 3 lines */
.carousel-text-content {
  position: relative;
  transition: opacity 0.5s ease;
  opacity: 1;
  overflow: hidden; /* Hide overflowed content */
  box-sizing: border-box; /* Ensures padding doesn't increase the width/height */
  line-height: 1.5em; /* Set the line height */
  height: calc(1.5em * 3); /* Set height to allow exactly 3 lines of text */
}

/* Force fixed sizes for text elements in the carousel */
.carousel h2 {
  font-size: 2rem;
  color: #fff;
  margin-bottom: 10px;
}

.carousel p {
  font-size: 1.2rem;
  color: #fff;
  margin-bottom: 15px;
}

/* Fixed size for image */
.skill-image {
  width: 100%;
  max-height: 300px; /* Limit the height of the image */
  object-fit: contain; /* Ensure the entire image fits in the container */
  display: block;
  margin-bottom: 10px; /* Add space between the image and the link */
}

/* Ensure link container remains within fixed size */
.image-link-container {
  display: flex;
  flex-direction: column; /* Stack the image and link vertically */
  align-items: center; /* Center them horizontally */
  width: 100%; /* Adjust to fit parent width */
  height: auto; /* Allow the height to adjust based on content */
  margin-bottom: 20px; /* Add space below the container */
}

.showcase-bubble {
  display: inline-block;
  padding: 5px;
  border: 2px solid #0077ff;
  border-radius: 10px;
  margin-bottom: 20px;
}

.project-link {
  margin-top: 10px;
  padding: 8px 16px;
  background-color: #0077ff;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
}

.project-link:hover {
  background-color: #005bb5;
}

/* Navigation buttons */
.carousel-button {
  position: absolute; /* Absolutely positioned within the carousel */
  top: 50%; /* Align buttons vertically in the middle of the carousel */
  transform: translateY(-50%); /* Center the buttons vertically */
  background: none;
  border: none;
  font-size: 2rem;
  cursor: pointer;
  color: #0077ff;
  padding: 10px;
  z-index: 100; /* Ensure buttons are above all content */
}

.carousel-button.prev {
  left: 10px; /* Align button on the left */
}

.carousel-button.next {
  right: 10px; /* Align button on the right */
}

/* Ensure carousel indicators are below the image */
.carousel-indicators {
  position: absolute; /* Keep indicators within the carousel */
  bottom: 10px; /* Move indicators to the bottom of the carousel */
  left: 50%; /* Center the indicators horizontally */
  transform: translateX(-50%); /* Center the indicators */
  display: flex;
  justify-content: center;
  z-index: 50; /* Keep indicators visible but below the buttons */
}

/* Indicator dots styling */
.carousel-indicators span {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  background-color: #ccc;
  border-radius: 50%;
  cursor: pointer;
}

.carousel-indicators .active {
  background-color: #0077ff;
}
